$base-line-height: 1.5 !default;
$spacing-unit: 1.875rem !default;

// Width of the content area
$content-width: 50rem !default;

$on-palm: 37.5rem !default;
$on-laptop: 50rem !default;

$on-medium: $on-palm !default;
$on-large: $on-laptop !default;
